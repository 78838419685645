//
// Environment
//

// Config values
$platform-name: "outlifeclone";

// Colours
$life: rgb(255,96,90) !default;
$lifeAccessibleLtLgText: #ff5e58 !default;
$healing: rgb(255,160,75) !default; 
$sunlight: rgb(255,210,50) !default;
$nature: rgb(123,240,200)!default;
$serenity: rgb(123,205,240) !default;
$serenityAccessibleLtLgText: #189ed7 !default;
$serenityAccessibleLinkText: #137eac !default;
$spirit: rgb(175,125,235) !default;
$poc-dk: rgb(80,50,50) !default;
$poc-lt: rgb(215,155,85) !default;
$trans-pink: rgb(255,175,175) !default;
$trans-blue: rgb(140,230,255) !default;
$brand-gradient: linear-gradient(90deg, $trans-blue 0%, $serenity 30%, $nature 100%) !default;

$brand-primary: $serenity;
$brand-secondary: $lifeAccessibleLtLgText;
$brand-gradient: linear-gradient(90deg, rgba(0,0,0,0.75) 0%, rgba(0,0,0,0) 100%) !default;

// Create greyscale
$black: #000 !default;
$grey-dark: #444 !default;
$grey: #464a4c !default;
$grey-light: #636c72 !default;
$grey-lighter: #eceeef !default;
$grey-lightest: #f7f7f9 !default;
$white: #fff !default;

// Layout
$container-max-width: 1230px !default;

// Spacers
$spacer: 1rem !default;
$spacer-y: $spacer !default;
$spacer-x: $spacer !default;

// Transitions
/*$transition-duration: 0.2s !default;
$transition-duration-slow: 0.5s !default;
$transition-curve: ease !default;*/

// Logo
$logo: "logoTagline.svg";
$logo-width: 250px;
$logo-height: 88px;

// Typography
$font-family-base: 'Work Sans', sans-serif !default;
$font-weight-bold: bold !default;
$headings-font-family: 'Roboto', sans-serif !default;
$headings-line-height: inherit;
//$headings-letter-spacing: 1px;
//$headings-text-transform: uppercase;
$headings-font-weight: 900;

$font-size-h1: 2.5em !default;
$font-size-h2: 2em !default;
$font-size-h3: 1.75em !default;
$font-size-h4: 1.5em !default;
$font-size-h5: 1.25em !default;
$font-size-h6: 1em !default;

// Links
$link-colour: $serenityAccessibleLinkText;

// Buttons
$btn-background-colour: $lifeAccessibleLtLgText;
$btn-padding-x: 1.5em;
$btn-padding-y: 1em;
$btn-text-colour: $white; /* override text-contrast ratio */
$btn-font-weight: $font-weight-bold;
$btn-text-transform: uppercase;
$btn-border-radius: 5px;

// Donate button
$donate-btn-colour: $white; /* override text contrast */

// Social icons (https://github.com/RaisingITDev/fresco/wiki/social-icons)
// Social network colours - use these by changing $social-icons-use-brand-colours to true
$twitter-brand-colour: #1da1f2 !default;
$facebook-brand-colour: #3b5998 !default;
$youtube-brand-colour: #ff0000 !default;
$instagram-brand-colour: #833ab4 !default;
$linkedin-brand-colour: #0077b5 !default;
$pinterest-brand-colour: #bd081c !default;
$tripadvisor-brand-colour: #00af87 !default;
$vimeo-brand-colour: #1ab7ea !default;

$social-icons-background-colour: transparent; // A colour, transparent, or 'brand'
$social-icons-colour: $black; // A colour, or 'brand'

// Form elements
$input-padding-y: 0.375rem !default;
$input-padding-x: 0.75rem !default;
$input-border-radius: 5px;

// Card
$card-border: none;

// Card hover state
$card-hover-image-opacity: 0.25;

// Menu admin
$menu-admin-enabled: false;

// Header
$header-background-gradient: $brand-gradient;

// Header content
$header-content-padding-top: 20px;
$header-content-padding-bottom: 20px;
$header-content-margin-top: 0;
$header-content-margin-bottom: 0;

// Search

// Search - input
$header-search-input-margin-right: -43px;
$header-search-input-padding: $input-padding-y 43px $input-padding-y $input-padding-x;
$header-search-input-max-width: 200px;
$header-search-input-placeholder-colour: $grey;
$header-search-input-border-radius: 5px;


// Search - button
$header-search-button-background-colour: transparent;
$header-search-button-icon-colour: $grey-light;

// Sticky header
$header-sticky-enabled: true;

// Navigation
$nav-type: normal; // normal, flyout, fullscreen // TODO: mega
$nav-font-family: $headings-font-family;
$nav-background-colour: #fff;
//$nav-background-colour: $grey-lightest;
$nav-top-level-item-colour: $black;
$nav-top-level-item-hover-colour: $black;
$nav-top-level-item-hover-background-colour: transparent;
//$nav-top-level-item-hover-colour: $brand-primary;
//$nav-text-transform: uppercase;

// Top level items
$nav-top-level-item-padding: 15px 20px;
$nav-top-level-item-font-weight: $font-weight-bold;
$nav-top-level-chevrons-enabled: true;
$nav-top-level-chevrons-colour: $brand-primary;

// Submenus
$nav-submenu-width: 350px;
$nav-submenu-background-colour: $white;
$nav-submenu-item-padding: 15px 20px !default;
$nav-submenu-item-colour: $white;
$nav-submenu-item-colour: $black;
$nav-submenu-item-hover-background-colour: $serenity;
$nav-submenu-item-hover-colour: $black;
//$nav-submenu-item-hover-colour: $nav-submenu-item-colour !default;
//$nav-submenu-item-hover-background-colour: rgba($black, 0.1) !default;
//$nav-submenu-chevrons-enabled: true !default;
//$nav-submenu-chevrons-colour: $nav-submenu-item-colour !default;

// Nav normal
$nav-normal-align-items: center; // left, center, right

// Carousel
$carousel-max-width: 100%;
$carousel-details-style: overlay; // overlay, adjacent or below
$carousel-details-background-colour: transparent;
$carousel-details-position-y: center; // top, center, bottom
$carousel-image-overlay: linear-gradient(90deg, rgba(0,0,0,0.75) 0%, rgba(0,0,0,0) 100%); // Add a colour value or gradient here
$carousel-heading-colour: $white;
$carousel-contents-max-width: $container-max-width;
//$carousel-read-more-background-colour: $brand-secondary;
$carousel-details-min-height: 100%; // set to 100% if you want to use overlay, but have the details full height pre-breakpoint
$carousel-summary-colour: $white;
$carousel-details-max-width: 500px;

// Carousel controls (left-right buttons)
$carousel-controls-enabled: true !default;
$carousel-controls-button-background-colour: $brand-primary;
$carousel-controls-position-y: center; // top, center, bottom
$carousel-controls-position-x: split; // split, left or right

// Carousel buttons (dots or tabs)
$carousel-buttons-enabled: false;

// Home feeds
$home-feeds-max-width: $container-max-width !default;
$home-feeds-padding-top: $spacer * 2 !default;
//$home-feeds-padding-top: 0;
$home-feeds-padding-bottom: $spacer * 2 !default;


// Feeds title
$feeds-title-text-transform: uppercase;

// Footer
$footer-boxes-count: 5;
$footer-background-colour: $grey-dark;
$footer-headings-font-size: $font-size-h3;
$footer-padding-y: $spacer * 4;
$footer-link-colour: $white;
$footer-link-decoration: none;




// Newsletter
$newsletter-background-gradient: linear-gradient(90deg, rgba(140,230,255,1) 0%, rgba(123,205,240,1) 30%, rgba(123,240,200,1) 100%);
$newsletter-heading-colour: $white;
$newsletter-heading-text-align: left; // left, center, right
$newsletter-use-placeholders: true; // Turn on to use placeholders in the inputs rather than labels
$newsletter-button-colour: $white;

// Associated lists
$associated-max-number-of-posts: 3 !default;

// Blockquote
$blockquote-background-colour: $grey-lighter;

// Shop
$shop-enabled: false; // Quickly disable shop on the platform (hide basket and not include relevant Sass for compilation)

// Subsite
$subsite-enabled: true;
// $subsite-hide-main-header: false !default;
// $subsite-hide-main-tagline: false !default;
// $subsite-hide-header-search: false !default;
// $subsite-hide-main-cta: false !default;
// $subsite-content-max-width: $container-max-width !default;
// $subsite-title-font-size: $page-title-font-size !default;

// Subsite logo
$subsite-logo: title; // Set to 'title' if you want to just use the subsite text title. If you use a logo image, it will act as a link back to the subsite's homepage
// $subsite-logo-retina: $subsite-logo !default;
// $subsite-logo-width: 120px !default;
// $subsite-logo-height: 120px !default;
// $subsite-logo-margin: $header-content-margin-top 0 0 !default;

// Banner min-height under sm breakpoint - helps to keep some vertical height at narrow viewports, especially for letter-box aspect ratios
$banner-min-height: 0;