//
// Home features
//

// Choose your overall home feature cards layout
.homeFeatures {
  @include cards-layout-prefab (
    $number-of-cards: 3,
    $prefab: 2
  );

  // Choose your card style for home feature cards
  .homeFeature {
    @include card;
    @include card-basic;
  }
}

// Alternatively, you can target specific features rather than styling them all
// If you do this, you will need to target them ALL uniquely, so:
// .homeBox1 {...}, .homeBox2 {...}, .homeBox3 {...} etc.


//
// Home feeds
//

// Choose your overall home feeds layout

@include home-feeds-prefab (
  $number-of-feeds: 3,
  $prefab: 1
);

// Specify a card layout for each individual feed
.homeFeed .feedList {
  @include cards-layout-prefab (
    $number-of-cards: 3,
    $prefab: 12
  );
}

.homeFeedBox1,
.homeFeedBox3{
  .feedList {
    @include cards-layout-prefab (
      $number-of-cards: 3,
      $prefab: 2
    );
  }
}

.homeFeedBox2 .feedList {
  @include cards-layout-prefab (
    $number-of-cards: 3,
    $prefab: 1
  );
}

// N.b. home feed box 4 and 5 done manually as only allowed max 3 prefabs

@media (min-width: map-get($breakpoints, md) + 1px) {

  .homeFeedBox4, .homeFeedBox5{
    width:50%;
    .feedlist {
      .feedItem{
        width:100%;
      }
    }
  } 

}

.homeFeedBox4,
.homeFeedBox5{
  .feedItem{
    @include card(
      $card-details-background-colour: $grey-lighter,
      $card-hover-details-background-colour: $grey-lighter,
      $card-heading-font-size: $font-size-h5,
      $card-summary-enabled: false
    );
    @include card-side-by-side;
  }
}


// Choose your card style for home feed cards
.feedItem {
  @include card;
  @include card-basic;
}


//
// Listed posts
//

// Choose your card style for listed post cards
// (not products, search result or embedded map item)
.listedPost:not(.listedProduct):not(.listedSearchResult):not(.listedMapItem) {
  @include card;
  @include card-basic;
}


//
// Listed products
//

// If these are exactly the same as your regular 'listed posts' above
// you can remove the ':not(.listedProduct)' selector above and squash this
// together into more efficient CSS output
.listedProduct {
  @include card;
  @include card-basic;
}