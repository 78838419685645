//@media (min-width: map-get($breakpoints, md)) {}

// Useful mixin for before and after dividers
@mixin beforeafter {
  content: "";
  position: absolute;
  left:0;
  width: 100vw;
  background-repeat: no-repeat;
  background-position: center;
  overflow: hidden;
  height:calc((25/250) * 100vw);
  background-size: 100% 100%;
}

// Navigation

#menuMain{
  margin-bottom: 0;
  max-width: 100%;
}

body.pf_noHeaderMedia{
  #menuMain{
    border-bottom: 1px solid rgba(46, 209, 237, .5);
  }
}

// Sticky nav

@media (min-width: map-get($breakpoints, md) + 1px) {
  .pageHeader.sticky .headerContent { display: none; }
}

// Colourful submenus

.menuMain ul.subMenu li {
  &:nth-of-type(1) > a{
    &:hover{
      background-color: $life;
    }
  }
  &:nth-of-type(2)> a{
    &:hover{
      background-color: $healing;
    }
  }
  &:nth-of-type(3)> a{
    &:hover{
      background-color: $sunlight;
    }
  }
  &:nth-of-type(4)> a{
    &:hover{
      background-color: $nature;
    }
  }
  &:nth-of-type(5)> a{
    &:hover{
      background-color: $serenity;
    }
  }
  &:nth-of-type(6)> a{
    &:hover{
      background-color: $spirit;
    }
  }
  &:nth-of-type(7)> a{
    &:hover{
      background-color: $poc-dk;
      color: $white;
    }
  }
  &:nth-of-type(8)> a{
    &:hover{
      background-color: $poc-lt;
    }
  }
  &:nth-of-type(9)> a{
    &:hover{
      background-color: $trans-pink;
    }
  }
  &:nth-of-type(10)> a{
    &:hover{
      background-color: $trans-blue;
    }
  }
}

// Carousel

@media (min-width: map-get($breakpoints, lg) + 1px) {

  .carouselSlide{
    position: relative;
    &:after {
      @include beforeafter;
      bottom:0;
      background-image: url($assets-path + "waveBottom.svg");
    }
  }

}

// Home feeds

.homeFeedBox2{
  position: relative;
  padding-top:150px;
  padding-bottom:200px;
  background: $brand-gradient;
  .feedsTitle{
    color: $white;
  }
  &:before {
    @include beforeafter;
    top:0;
    background-image: url($assets-path + "waveTop.svg");
  }
  &:after {
    @include beforeafter;
    bottom: 0;
    background-image: url($assets-path + "waveBottom.svg");
  }
}

@media (min-width: map-get($breakpoints, md) + 1px) {
  .homeFeedBox4and5Wrapper {
    max-width: 1230px;
    margin: auto;
    display: flex;
  }
}

.homeFeedBox4and5Wrapper {
  position: relative;
  &:before {
    @include beforeafter;
    top:0;
    background-image: url($assets-path + "waveTop.svg");
  }
}

.homeFeedBox4,
.homeFeedBox5{
 // padding-top: $spacer * 2;
  //padding-bottom: $spacer * 3;
}


.footerBox1.NewsletterSign-up{
  position: relative;
  padding-top:175px;
  padding-bottom:100px;
  &:before {
    @include beforeafter;
    top:0;
    background-image: url($assets-path + "waveTop.svg");
  }
}
  
// Posts

.pagesPostBody:not(.body.pf_noHeaderMedia){
  .headerText {
    padding-top:0;
  }
  .breadcrumbList{
    margin-top:0;
  }
}

// Sidebar

.associated:not(.associatedBox) .associated-image-link{
  display: block;
}

// Footer

.pageFooter a:not(.button){
  font-weight: bold;
}

.footerBox5{
  font-size: .8rem;
}

// Blockquote
blockquote{
/*  position:relative;
  margin-top:100px;
  margin-bottom:100px;
  &:before {
    @include beforeafter;
    top:-100px;
    background-image: url($assets-path + "waveBlockquoteTop.svg");
    width:100%;
    height:100px;
  }&:after {
    @include beforeafter;
    bottom:0;
    background-image: url($assets-path + "waveBlockquoteBottom.svg");
    width:100%;
    height:100px;
    bottom:-100px;
  }*/
}

// Header no banner
.pf_noHeaderMedia .title {
  margin-top: 5rem;
}

table .mediaImage {
  height: auto;
}